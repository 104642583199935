<template>
  <div>
    <CModal
        title="New Sales Office"
        size="xl"
        :show.sync="showModal"
        @update:show="closeAddModal"
    >
      <CAlert
          v-if="error"
          color="danger"
          closeButton
          @update:show="removeError">
        {{error}}
      </CAlert>
      <SalesOfficeForm
          v-bind:sales-office-in="salesOffice"
          v-bind:vendor-nid="this.$route.params.vendor_nid"
          v-on:update-item="updateItem"
          v-on:error="catchError"
      />
    </CModal>
  </div>
</template>

<script>
import SalesOfficeForm from "@/apps/partners/components/SalesOfficeForm";
import gql from "graphql-tag";

const createSalesOffice = gql`
  mutation createSalesOffice($salesOffice: SalesOfficeInput){
    createSalesOffice(salesOffice: $salesOffice) {
      nid
      name
      activePapp
      msActivePapp
      imActivePapp
      vendor {
        shortName
      }
    }
  }
`

export default {
  name: "SalesOfficeAdd",
  components: {
    SalesOfficeForm
  },
  data(){
    return {
      salesOffice: {
        "msSendHtmlInstantHandover": false,
        "msHtmlInstantHandoverEmails": [],
        "msHtmlInstantHandoverTemplate": "ms_html_instant_handover",
        "msSendCsvHandover": false,
        "msCsvHandoverEmails": [],
        "msCsvHandoverSchedule": "instant",
        "msCsvHandoverTemplate": "ms_csv_handover_massivhaus",
      },
      salesOfficeSave: {},
      error: null
    }
  },
  computed: {
    showModal: {
      get: function() {
        return this.$store.state.addModalShow
      },
      set: function (){
      }
    }
  },
  methods: {
    updateItem(data){
      this.salesOfficeSave = data;
    },
    closeAddModal(status, evt, accept){
      if (accept) {
        this.$apollo.mutate({
          mutation: createSalesOffice,
          variables: {
            salesOffice: this.salesOfficeSave
          },
        })
        .then(res => {
          this.$emit('add-item', res.data.createSalesOffice);
          this.salesOffice = {};
          this.salesOfficeSave = {};
          this.$store.commit('set', ['addModalShow', false]);
        })
        .catch(error => {
          console.error(error);
          this.error = error.message;
        })
       }else{
        this.contract = {};
        this.contractSave = {};
        this.$store.commit('set', ['addModalShow', false]);
       }
    },
    catchError(event){
      this.error = event
    },
    removeError(hide){
      if(!hide){
        this.error = null
      }
    }
  }
}
</script>
